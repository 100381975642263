<ng-template #showMyProjectsTmpl let-label>
  <a
    [routerLink]="routing.projects"
    *ngIf="isLoggedIn"
    class="rb-btn rb-primary col-md-4 mr-2 ml-2 mb-2 block"
  >
    {{ label }}
  </a>
</ng-template>
<div
  class="container-fluid home p-md-5 p-2"
  [ngClass]="{
    whitelabel: (appTheming.appTheme$ | async).theme === 'CUSTOM',
    insights: (appTheming.appTheme$ | async).theme === 'INSIGHTS'
  }"
  data-cy="homeContainer"
>
  <div
    class="page-background"
    [ngStyle]="{ 'background-image': appTheming.appTheme?.backgroundImage }"
  ></div>
  <div class="container">
    <div class="col-8 mt-3 mt-md-5 mb-3 mb-md-5">
      <div class="row">
        <div class="col-12 h4 home-logo">
          <img
            *ngIf="(appTheming.appTheme$ | async).theme === 'INSIGHTS'"
            src="assets/img/Picture2.png"
            alt="Bosch IoT Insights"
          />
        </div>
      </div>
      <div class="row mt-3 pl-3 hidden-if-whitelabel">
        <a
          href="https://www.bosch-iot-suite.com/explore/"
          *ngIf="!isLoggedIn"
          class="rb-btn rb-primary col-md-4 mr-2 ml-2 mb-2 block"
        >
          {{ 'home.tryNow' | translate }}
        </a>
        <ng-container
          *ngTemplateOutlet="
            showMyProjectsTmpl;
            context: { $implicit: 'home.showMyProjects' | translate }
          "
        ></ng-container>
      </div>
      <div class="row mt-2 ml-2 mt-md-6" *ngIf="(appTheming.appTheme$ | async).theme === 'CUSTOM'">
        <ng-container
          *ngTemplateOutlet="showMyProjectsTmpl; context: { $implicit: 'home.start' | translate }"
        ></ng-container>
        <login-provider-select
          class="col-md-4 px-0 block"
          *ngIf="!isLoggedIn"
          (loginClicked)="login($event)"
        ></login-provider-select>
      </div>
      <div class="row mt-2 mt-md-4">
        <div class="col-12 h2 pb-0 mb-0 slogan">
          <div class="slogan-text">
            {{ 'home.slogan' | translate }}
          </div>
        </div>

        <div class="col-12">
          <div class="teaser">
            <div class="teaser-text">
              {{ 'home.teaser' | translate }}
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-2">
        <div class="ml-3 iso-logo">
          <a href="https://www.iso.org/standard/27001" target="_blank">
            <img
              src="assets/img/information_security_iso_27001.png"
              alt="Information Security ISO/IEC 27001 certificate"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
