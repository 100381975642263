import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EffectRef,
  ElementRef,
  HostBinding,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  ActivatedRoute,
  IsActiveMatchOptions,
  NavigationEnd,
  NavigationError,
  Router
} from '@angular/router';
import {
  AppLayoutSideNavigationModel,
  AppSideNavigationEvent,
  DialogCloseEventWithResult
} from '@inst-iot/bosch-angular-ui-components';
import { LocalStorageService } from 'ngx-localstorage';
import {
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  startWith,
  takeUntil,
  takeWhile
} from 'rxjs/operators';
import { Constants } from '../../../constants';
import { translate } from '../../shared/translation-util';
import { AppThemingService } from '../services/app-theming.service';
import { FullscreenService } from '../services/fullscreen.service';
import { GlobalAlertService, GlobalError } from '../services/global-alert.service';
import { NavigationSubItem } from '../services/models/side-navigation.model';
import { SystemRole } from '../services/models/system-role';
import {
  InsightsNavigation,
  NavigationFactoryService
} from '../services/navigation-factory.service';
import { UserAuthService } from '../services/user-auth.service';
import { FooterComponent } from './footer/footer.component';
import { FullscreenBaseComponent } from './fullscreen-base.component';
import { HeaderComponent } from './header/header.component';
import { LayoutService } from './layout.service';
import { NotificationDisplayComponent } from './notification-display/notification-display.component';
import { DashboardLabelService } from '../../shared/services/dashboard-label.service';
import { TranslateService } from '@ngx-translate/core';
import { partition } from 'rxjs';
import { FooterRichtextTitleService } from '../../content-pages/footer/services/footer-richtext-title.service';
import { SubscriptionStatus } from '../../project-admin/subscription-details/subscription-details.model';
import { ProjectRole } from '../services/models/project-role';

@Component({
  selector: 'layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LayoutComponent
  extends FullscreenBaseComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  sideNavigation: AppLayoutSideNavigationModel;

  readonly routing = Constants.routing;

  private isSidebarHidden = false;

  @HostListener('window:keyup', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (event.code === 'Escape' && this.fullscreenService.isActive.getValue()) {
      this.fullscreenService.isActive.next(false);
    }
  }

  @HostBinding('class.hide-sidebar') get hideSidebar() {
    return this.isSidebarHidden;
  }

  @ViewChild(HeaderComponent, { static: true })
  headerComponent: HeaderComponent;

  @ViewChild(FooterComponent, { static: true })
  footerComponent: FooterComponent;

  @ViewChild(NotificationDisplayComponent, { static: true })
  notificationComponent: NotificationDisplayComponent;

  destroyed = false;

  private richtextTitleEffect: EffectRef;

  constructor(
    elementRef: ElementRef,
    public layoutService: LayoutService,
    public authService: UserAuthService,
    public navigationFactory: NavigationFactoryService,
    public appTheming: AppThemingService,
    public fullscreenService: FullscreenService,
    public router: Router,
    private localStorageService: LocalStorageService,
    private alertService: GlobalAlertService,
    private cdr: ChangeDetectorRef,
    private dashboardLabelService: DashboardLabelService,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private footerRichtextTitleService: FooterRichtextTitleService
  ) {
    super(fullscreenService, elementRef);
  }

  ngOnInit(): void {
    this.initPageTitleChangeObserver();
    this.subscribeToNavigationEvents();
    this.subscribeToSideNavigationChange();
  }

  initPageTitleChangeObserver() {
    this.layoutService.currentHeaderTitle
      .pipe(
        takeWhile(() => !this.destroyed),
        distinctUntilChanged()
      )
      .subscribe((headerTitle) => {
        this.headerComponent.updateHeader(headerTitle);
      });
  }

  ngAfterViewInit(): void {
    this.dashboardLabelService.currentLabel$
      .pipe(filter(Boolean), takeUntil(this.destroy))
      .subscribe((label) => {
        if (this.isDashboardRoute()) {
          this.headerComponent.updateHeader(label);
        }
      });

    this.setBottomMenuItemsHeightSameAsFooter();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.destroyed = true;
  }

  savePinState($event: any) {
    this.localStorageService.set('layoutPinnedState', $event);
    // Trigger "resize" event for dashboard-grid and all other components listening to "window.resize"
    window.dispatchEvent(new Event('resize'));
  }

  findItemActive(): NavigationSubItem {
    const match = (item: NavigationSubItem): IsActiveMatchOptions =>
      this.matchOptions(item.routerActiveExactMatch ? 'exact' : 'subset');

    let found: NavigationSubItem;
    this.sideNavigation?.menuItems?.forEach((menuItem) => {
      if (menuItem.routerLink && this.router.isActive(menuItem.routerLink, match(menuItem))) {
        found = menuItem;
      }
      menuItem.subItems?.forEach((subItem) => {
        if (subItem.routerLink && this.router.isActive(subItem.routerLink, match(subItem))) {
          found = subItem;
        }
      });
    });
    return found;
  }

  matchOptions(strategy: 'exact' | 'subset' = 'exact'): IsActiveMatchOptions {
    return {
      paths: strategy,
      matrixParams: 'ignored',
      queryParams: 'ignored',
      fragment: 'ignored'
    };
  }

  closeFullScreen() {
    this.fullscreenService.deactivate();
  }

  private getDeepestActiveRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

  private updateHeaderTitle(data: any): void {
    const item = this.findItemActive();

    // if there is an active item (except for the projects view) the title update is triggered by the side navigation (subscribeToSideNavigationChange)
    // else the title is updated by the data of the current route
    if (item && item.id !== 'projects') {
      return;
    }

    const isFooterRichtextLink = data['isFooterRichtextLink'];

    if (isFooterRichtextLink) {
      this.richtextTitleEffect = this.footerRichtextTitleService.createTitleEffect((title) => {
        this.headerComponent.updateHeader(title);
      });
    } else {
      this.headerComponent.updateHeader(
        data['i18nTitle'] ? this.translateService.instant(data['i18nTitle']) : ''
      );
    }

    this.checkSidebarState();
  }

  /**
   * @param event - The navigation event
   * @result - Returns true if the event is null (initial url load) or an instance of NavigationEnd,
   * and the current route is not the dashboard route (handled in ngAfterViewInit)
   */
  private shouldProcessNavEvent(event: any): boolean {
    return (event === null || event instanceof NavigationEnd) && !this.isDashboardRoute();
  }

  private checkSidebarState(): void {
    const options: IsActiveMatchOptions = this.matchOptions('subset');

    // hide sidebar when at /home
    if (this.router.isActive(this.routing.home, options)) {
      this.isSidebarHidden = true;
      return;
    }

    // hide sidebar when at /projects for none system roles
    if (
      this.router.isActive(this.routing.projects, options) &&
      !this.authService.hasAnySystemRole([SystemRole.manager, SystemRole.admin])
    ) {
      this.isSidebarHidden = true;
      return;
    }

    // hide sidebar when no sidebar is there (e.g. under /user-settings)
    if (this.router.isActive(this.routing.userInfo, options)) {
      this.isSidebarHidden = true;
      return;
    }

    this.isSidebarHidden = false;
  }

  private subscribeToSideNavigationChange(): void {
    this.navigationFactory.insightsNavigation$
      .pipe(takeUntil(this.destroy))
      .subscribe((nav: InsightsNavigation) => {
        this.appTheming.setThemeProps(this.layoutService.projectConfig);

        this.sideNavigation = {
          appName: '',
          menuItems: nav.side,
          bottomMenuItems: nav.bottom,
          isPinned: this.localStorageService.get('layoutPinnedState') ?? false,
          actionPerformed: (event: AppSideNavigationEvent) => {
            // do not change the page title when system menu switch is clicked
            if (event.action && event.action.id !== 'switch') {
              this.headerComponent.updateHeader(event.action.title);
            }
            this.setBottomMenuItemsHeightSameAsFooter();
          }
        };
        this.disableMenuItemsForStandby();

        this.checkSidebarState();
      });
  }

  private subscribeToNavigationEvents(): void {
    // subscribe to events until one event after component was destroyed to catch project navigation error (error gets thrown after component is destroyed)
    const [errorRouterEvents$, nonErrorRouterEvents$] = partition(
      // Emit a value immediately (startWith operator) to handle initial load
      this.router.events.pipe(startWith(null)),
      (event) => event instanceof NavigationError
    );

    errorRouterEvents$.pipe(takeWhile(() => !this.destroyed, true)).subscribe(() => {
      this.alertService
        .showError(GlobalError.NavigationFailed, translate('error.reload'))
        .then((reload: DialogCloseEventWithResult) => {
          if (reload.event === 'confirmed') {
            location.reload();
          }
        });
    });

    nonErrorRouterEvents$
      .pipe(
        takeWhile(() => !this.destroyed, true),
        filter((event) => this.shouldProcessNavEvent(event)),
        map(() => this.getDeepestActiveRoute(this.activatedRoute)),
        mergeMap((route) => route.data)
      )
      .subscribe((data) => {
        this.clearRichtextTitleEffect();
        this.updateHeaderTitle(data);
      });
  }

  private setBottomMenuItemsHeightSameAsFooter() {
    const footerEl = document.querySelector('footer');
    const footerElHeight = footerEl?.getBoundingClientRect().height;

    if (!footerElHeight) {
      return;
    }
    if (this.sideNavigation?.bottomMenuItems) {
      this.sideNavigation.bottomMenuItems = this.sideNavigation.bottomMenuItems.map((item) => ({
        ...item,
        height: footerElHeight
      }));
    }

    this.cdr.detectChanges();
  }

  private isDashboardRoute(): boolean {
    return this.router.url.includes('dashboards');
  }

  private clearRichtextTitleEffect(): void {
    this.richtextTitleEffect?.destroy();
  }

  private disableMenuItemsForStandby(): void {
    if (
      this.layoutService.projectConfig?.status !== SubscriptionStatus.standby ||
      this.authService.hasSystemRole(SystemRole.admin)
    ) {
      return;
    }

    this.sideNavigation.menuItems.forEach((item) => {
      if (
        item.id === 'admin' &&
        this.authService.hasProjectRole(ProjectRole.owner(this.layoutService.projectConfig.name))
      ) {
        item.subItems?.forEach((subItem) => {
          if (!['subscriptionDetails', 'usageConfig'].includes(subItem.id)) {
            subItem.disabled = true;
          }
        });
      } else {
        item.disabled = true;
      }
    });
  }
}
