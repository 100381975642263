import { Component, EventEmitter, Output, inject, OnDestroy, OnInit } from '@angular/core';
import { UserApiService } from '../../../../project-admin/user/services/user-api.service';
import { PaginationSettingsService } from '../../../../shared/pagination-bar/pagination-settings.service';
import { User } from '../../../../shared/api-model';
import { tap } from 'rxjs/operators';
import { RoleApiService } from '../../../../project-admin/role/services/role-api.service';
import { LoadingEntity } from '@inst-iot/bosch-angular-ui-components';
import { Role } from '../../../../project-admin/role/models/role.model';
import { ProjectsService } from '../../../../shared-projects/services/projects.service';
import { UserAuthService } from '../../../services/user-auth.service';
import { MinimalUser } from '../../../../dashboards/widgets/calendar-widget/user-selector/user-selector.component';

@Component({
  selector: 'user-role-overwrite',
  templateUrl: './user-role-overwrite.component.html',
  providers: [UserApiService, PaginationSettingsService]
})
export class UserRoleOverwriteComponent implements OnInit, OnDestroy {
  @Output() done = new EventEmitter<boolean>();
  userRoles: string[] = [];
  roleSelection: string[] = [];
  rolesLoader = new LoadingEntity<any>();
  usersLoader = new LoadingEntity<any>();
  selectedUser: MinimalUser;

  protected userService: UserApiService = inject(UserApiService);
  protected roleService: RoleApiService = inject(RoleApiService);
  protected authService: UserAuthService = inject(UserAuthService);
  protected projectService: ProjectsService = inject(ProjectsService);

  hasRolesOverwritten: boolean = this.authService.hasRolesOverwritten();

  ngOnInit() {
    this.loadUserRoles();
  }

  ngOnDestroy(): void {
    this.usersLoader.complete();
    this.rolesLoader.complete();
  }

  onUserSelected(user: User) {
    this.selectedUser = user as MinimalUser;
    this.rolesLoader
      .run(
        this.userService.getUserRoleMappings(user.userId).pipe(
          tap((roles) => {
            this.userRoles = roles.map((role: Role) => role.roleName);
            this.roleSelection = [...this.userRoles];
          })
        )
      )
      .subscribe();
  }

  saveUserRoles() {
    this.rolesLoader
      .run(this.roleService.overwriteSessionRole(this.roleSelection))
      .subscribe(() => this.refreshSession());
  }

  cancelUserRoles() {
    this.done.emit();
  }

  resetUserRoles() {
    this.usersLoader
      .run(this.roleService.removeSessionRoleOverwrite())
      .subscribe(() => this.refreshSession());
  }

  onRoleSelectionChange(roles: string[]) {
    this.roleSelection = roles;
    // Reset the user if the roles are changed, ensuring no dependencies on selectedUser's email and userId exist
    this.selectedUser = { email: null } as MinimalUser;
  }

  private refreshSession() {
    location.reload();
  }

  private loadUserRoles() {
    if (this.authService.hasRolesOverwritten()) {
      this.userRoles = this.authService.projectRoles[this.projectService.projectName] || [];
    }
  }
}
