<ng-template #languageSelector>
  <language-selector></language-selector>
</ng-template>

<ng-template #projectSelector>
  <project-selector></project-selector>
</ng-template>

<ng-template #pageSelector>
  <page-selector></page-selector>
</ng-template>

<ng-template #userMenu>
  <user-menu *ngIf="layoutService.isLoggedIn"></user-menu>

  <login-provider-select
    *ngIf="!layoutService.isLoggedIn && !loginStatus.loading"
    [inHeader]="true"
    (loginClicked)="login($event)"
  ></login-provider-select>
</ng-template>

<ng-template #additionalButtonsTpl>
  <button
    type="button"
    class="a-button a-button--integrated"
    aria-label="Save Action Button"
    (click)="dashboardActionService.triggerAction(dashboardAction.SAVE_RELAYOUT)"
    [title]="'dashboard.saveRelayout' | translate"
  >
    <i class="a-icon a-button__icon boschicon-bosch-ic-save px-1"></i>
  </button>
  <button
    type="button"
    class="a-button a-button--integrated"
    aria-label="Discard Action Button"
    (click)="dashboardActionService.triggerAction(dashboardAction.DISCARD_RELAYOUT)"
    [title]="'dashboard.discardRelayout' | translate"
  >
    <i class="a-icon a-button__icon boschicon-bosch-ic-close px-1"></i>
  </button>
</ng-template>
