<div class="h-100">
  <div *ngIf="isLoading()" style="text-align: center; margin: 20px">
    <h1 *ngIf="isProjectRoute" [ngClass]="{ invisible: !projectLabel }">
      <span *ngIf="!projectLabel">placeholder</span>
      <span *ngIf="projectLabel" data-cy="projectLabel">{{ projectLabel | translated }}</span>
    </h1>
    <h1
      *ngIf="!isProjectRoute"
      [ngClass]="{
        invisible: !projectLabel && (appTheming.appTheme$ | async).theme !== 'INSIGHTS'
      }"
      data-cy="insightsLabel"
    >
      <span *ngIf="(appTheming.appTheme$ | async).theme === 'INSIGHTS'">Bosch IoT Insights</span>
      <span *ngIf="(appTheming.appTheme$ | async).theme !== 'INSIGHTS'">
        {{ !projectLabel ? 'placeholder' : (projectLabel | translated) }}
      </span>
    </h1>
    <p>{{ 'app.loading' | translate }}</p>
  </div>
  <rb-callout *ngIf="error">
    <rb-error-format [error]="error"></rb-error-format>
  </rb-callout>

  <router-outlet *rbLoading="isLoading(); size: 2"></router-outlet>
</div>
